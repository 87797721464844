<template>
  <QuizAndGameShared
    :session="session"
    class="GameView"
    :external-result="externalResult"
    @on-result="onResult()"
    @request-exit="exit()">
    <template #result="{ s }">
      <QuizResult
        :completed="checkCompleted()"
        :title="s.progress === 1 ? $t('titleSuccess') : $t('titleFail')"
        :text="$t(`${gameId}.text`)"
        :score="s.correctCount / s.totalCount"
        :image="gameImages[gameId]"
        :buttons="s.progress < 1 ? buttons : null"
        icon="game-icon"
        @action="handleAction" />
    </template>
  </QuizAndGameShared>
</template>

<translations>
  titleSuccess: 'Congrats, everything correct!'
  titleSuccess_no: 'Gratulerer, alt riktig!'

  titleFail: 'Game over!'

  redo: 'Try again'
  redo_no: 'Prøv på nytt'

  back: 'Back to memo'
  back_no: 'Tilbake til memo'

  gameA:
    title: 'Words to images'
    title_no: 'Ord til bilder'
    text: 'Match the highlighted words from the summary with the right memory images.'
    text_no: 'Match nøkkelordene fra sammendraget med huskebildene.'

  gameB:
    title: 'Images to story'
    title_no: 'Bilder til historie'
    text: 'Visualize the story with the memory images and choose them in the right sequence to recreate the summary.'
    text_no: 'Se for deg historien med huskebildene og velg dem i riktig rekkefølge for å gjenskape sammendraget.'
</translations>

<script>
import QuizAndGameShared from '../components/QuizAndGameLayout';
import QuizSession from '@/modules/quiz/quiz-session';
import quizHelper from '@/modules/course/quiz-helper';
import QuizResult from '../components/QuizResult';

import imageGameA from '../components/MemoGamesCardSeparateGames/game-a.svg';
import imageGameB from '../components/MemoGamesCardSeparateGames/game-b.svg';
import extractGameStates from '@/modules/course/extractGameStates';

export default {
    components: { QuizAndGameShared, QuizResult },
    props: {
        stepId: {
            type: String,
            required: true
        },
        gameId: {
            type: String,
            default: ''
        },
        activityName: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            completed: false,
            session: null
        };
    },
    created() {
        this.externalResult = !this.gameId;
        this.gameImages = {
            gameA: imageGameA,
            gameB: imageGameB
        };

        this.buttons = [
            {
                label: this.$t('redo'),
                action: 'redo'
            },
            {
                label: this.$t('back'),
                action: 'exit'
            }
        ];
    },
    mounted() {
        this.start();
    },
    methods: {
        async start() {
            const activity = this.$store.state.moduleCourse.steps[this.stepId][this.activityName];
            const options = activity.options ? JSON.parse(activity.options) : null;

            const dataProvider = await quizHelper.createQuestionProvider(
                { generator: { gameA: 'memoGameA', gameB: 'memoGameB', '': 'memoGameAB' }[this.gameId] },
                { stepId: this.stepId, lstore: this.$store, activityName: this.activityName, options }
            );
            this.session = new QuizSession(dataProvider, { lives: 3, requeue: false });
        },
        checkCompleted() {
            const activity = this.$store.state.moduleCourse.steps[this.stepId][this.activityName];
            const memos = activity.memoIds.map(memoId => this.$store.state.moduleCourse.memos[memoId]);
            const gameStates = extractGameStates(memos);
            if (this.gameId) {
                return gameStates[this.gameId];
            } else {
                return Object.values(gameStates).every(x => x);
            }
        },
        onResult() {
            const success = this.session.progress === 1;
            quizHelper.lastQuizSessionResult = {
                progress: this.session.progress,
                score: this.session.correctCount / this.session.totalCount
            };
            if (success) {
                const gameIds = this.gameId ? [this.gameId] : ['gameA', 'gameB'];
                gameIds.forEach(gameId => {
                    this.$store.dispatch('moduleCourse/markMemoGameAsDone', {
                        stepId: this.stepId,
                        activityName: this.activityName,
                        gameId
                    });
                });

                if (!this.externalResult) {
                    setTimeout(() => {
                        this.exit(true);
                    }, 2000);
                }
            }
            if (this.externalResult) {
                setTimeout(() => {
                    this.exit(success);
                }, 1000);
            }
        },
        handleAction(actionName) {
            this[actionName]();
        },
        redo() {
            this.start();
        },
        exit(success) {
            if (this.activityName === 'memorize2') {
                this.$router.replace({
                    name: 'GamesView',
                    params: {
                        activityName: this.activityName
                    }
                });
            } else {
                const query = {};
                if (success && this.gameId === 'gameA') {
                    query.initialFantasyMode = 'STORY';
                    query.initialMnemonicIndex = -1;
                } else {
                    query.initialFantasyMode = { gameA: 'IMAGE', gameB: 'STORY' }[this.gameId];
                    query.initialMnemonicIndex = success ? -2 : -1;
                }
                this.$router.replace({
                    name: 'Course-ActivityRedirect',
                    params: {
                        activityName: this.activityName
                    },
                    query
                });
            }
        }
    }
};
</script>
